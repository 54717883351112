<template>
	<v-layout column>
		<v-flex v-t="'customization.welcome_message_title'" mb-2 shrink title />
		<w-text-info :text="$t('customization.welcome_message_info')" />
		<v-layout row mt-1>
			<v-flex md6 xs12>
				<v-layout>
					<w-text-input
						v-model="welcomeMessage"
						clearable
						:label="$t('welcome_message')"
						:loading="loaders.textInput"
						@keypress.enter="onKeyPressEnter"
					></w-text-input>
				</v-layout>
				<v-layout
					:class="{
						'justify-end': $vuetify.breakpoint.mdAndUp,
						'justify-space-around': $vuetify.breakpoint.smAndDown
					}"
					row
				>
					<w-btn
						color="secondary"
						:disabled="isDefault"
						icon="settings_backup_restore"
						:loading="loaders.resetBtn"
						:small="$vuetify.breakpoint.xsOnly"
						@click="onClickReset"
						>{{ $t('customization.reset') }}</w-btn
					>
					<w-btn-save :disabled="!isModified" :flat="false" :loading="loaders.saveBtn" :small="$vuetify.breakpoint.xsOnly" @click="onClickSave"></w-btn-save>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomizationModuleGuard from '@/mixins/ModulesGuards/Customization/CustomizationModuleGuard'

export default {
	name: 'WelcomeMessageForm',
	mixins: [CustomizationModuleGuard],
	data: function () {
		return {
			loaders: {
				resetBtn: false,
				saveBtn: false,
				textInput: false
			},
			welcomeMessage: null
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		defaultMessage: function () {
			return this.$t('home.title', { entityName: this.accountingFirm.name })
		},
		isDefault: function () {
			return this.defaultMessage === this.welcomeMessage
		},
		isModified: function () {
			return this.welcomeMessage !== this.accountingFirm?.welcome_message
		}
	},
	watch: {
		'accountingFirm.welcome_message': {
			handler: 'copyWelcomeMessage',
			immediate: true
		}
	},
	methods: {
		/**
		 * @param {?string} welcomeMessage
		 */
		copyWelcomeMessage: function (welcomeMessage) {
			new Promise(resolve => {
				if (!welcomeMessage) {
					this.welcomeMessage = this.defaultMessage
				} else {
					this.welcomeMessage = welcomeMessage
				}
				resolve()
			})
		},
		/**
		 * @return {void}
		 */
		onClickReset: function () {
			this.loaders.resetBtn = true

			this.reset().finally(() => {
				this.loaders.resetBtn = false
			})
		},
		/**
		 * @return {void}
		 */
		onClickSave: function () {
			this.loaders.saveBtn = true

			this.save().finally(() => {
				this.loaders.saveBtn = false
			})
		},
		/**
		 * @return {void}
		 */
		onKeyPressEnter: function () {
			this.loaders.textInput = true

			this.save().finally(() => {
				this.loaders.textInput = false
			})
		},
		/**
		 * @return {Promise}
		 */
		reset: function () {
			return new Promise(resolve => {
				this.welcomeMessage = this.defaultMessage
				resolve()
			}).then(() => {
				return this.update(this.welcomeMessage)
			})
		},
		/**
		 * @return {Promise}
		 */
		save: function () {
			return new Promise(resolve => {
				if (this.isModified) {
					let welcomeMessage = this.welcomeMessage
					if (!welcomeMessage) {
						welcomeMessage = this.defaultMessage
					}
					const promise = this.update(welcomeMessage)

					resolve(promise)
				}

				resolve()
			})
		},
		/**
		 * @param {string} welcomeMessage
		 *
		 * @return {Promise}
		 */
		update: function (welcomeMessage) {
			return this.service.modifyWelcomeMessage(this.accountingFirm.id, welcomeMessage)
		}
	}
}
</script>
